export const questionFormats: ObjectStringKeyAnyValue = {
    "YesOrNo": { "displayName": "Yes or No" },
    "SelectOne": { "displayName": "Multi choice list (select one)" },
    "SelectMultiple": { "displayName": "Multi choice list (select multiple)" },
    "Photo": { "displayName": "Photo" },
    "Text": { "displayName": "Text" },
    "MultipleTextFields": { "displayName": "Multiple text fields" },
    "Number": { "displayName": "Number" },
    "FridgeTemperature": { "displayName": "Fridge or Freezer Temperature" },
    "PhotoAndReading": { "displayName": "Photo and Reading" }
}