

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
    fridgeId: string | null
}

function FormatFridgeName({
    fridgeId
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)

    const fridgeName = fridgeId && tableData && tableData.Assets && tableData.Assets[fridgeId] && tableData.Assets[fridgeId].AssetName


    if (!fridgeId) {
        return null
    }


    return <span>
        {fridgeName ?
            ` "${fridgeName}" (#${fridgeId}): ` :
            ` fridge Id #${fridgeId}: `
        }
    </span>
}

export default FormatFridgeName